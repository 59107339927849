<template>
  <div class="v-login">
    <div class="v-login__top-part">
      <img
        alt="trafikkopplaring.no"
        class="v-login__logo"
        src="/img/login/login-logo.svg"
      />
    </div>
    <div class="v-login__middle-part">
      <div>
        <TheHeading level="h1" class="v-login__heading">
          {{ $t('LOGIN_HEADING') }}
        </TheHeading>
        <div class="v-login__desc">{{ $t('LOGIN_DESC') }}</div>
      </div>
      <button class="v-login__btn" @click="login">{{ $t('LOGIN_BTN') }}</button>
    </div>
    <div class="v-login__bottom-part">
      <div class="v-login__bottom-desc">
        {{ $t('LOGIN_DESC2') }}
      </div>
      <a href="https://www.fagbokforlaget.no/" class="v-login__link">
        {{ $t('LOGIN_LINK') }}</a
      >
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useHead } from '@vueuse/head'
import { useTenant, useAuth } from '@/core'
import TheHeading from '@/components/TheHeading.vue'

export default defineComponent({
  components: { TheHeading },

  setup() {
    const { tenant } = useTenant()
    const { login } = useAuth()

    useHead({
      title: computed(() => `${tenant.value.name}`),
    })

    return {
      login,
    }
  },
})
</script>
<style lang="scss">
#app {
  max-width: 100vw;
}
.v-login {
  min-height: calc(100vh - 2.5rem);
  padding: 1.25rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  background-image: url('https://res.cloudinary.com/edtech/image/upload/w_900/v1649249972/coss_prod/2519c500-9e26-42aa-88fe-4f0c50009b13/1b28f98ad8b18b4408b3dc3bd34a6b52f6be6710e35040e80f596a5b907e6b1c.png');
  display: grid;
  grid-template-rows: 60px auto 185px;
  grid-template-columns: 1fr;

  &__top-part {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__middle-part {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__bottom-part {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  &__logo {
    width: 100%;
    max-width: 300px;
  }
  &__heading {
    margin: 0;
  }
  &__btn {
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
    border: 1px solid $login-btn-border;
    background-color: $btn-basic-bg;
    color: $color-15;
    cursor: pointer;
  }
  &__link {
    font-size: 1.25rem;
    font-weight: 700;
    color: $login-link;
    padding-top: 0.5rem;
    padding-bottom: 3.5rem;
  }

  @include bp(medium) {
    background-image: url('https://res.cloudinary.com/edtech/image/upload/fl_lossy,w_auto/v1649249972/coss_prod/2519c500-9e26-42aa-88fe-4f0c50009b13/1b28f98ad8b18b4408b3dc3bd34a6b52f6be6710e35040e80f596a5b907e6b1c.png');

    &__middle-part {
      justify-content: center;
      padding-left: 4rem;
    }
    &__heading {
      font-size: 4.5rem;
      max-width: 880px;
    }
    &__desc {
      padding: 1rem 0;
      font-size: 3rem;
    }
    &__btn {
      max-width: 320px;
    }
    &__bottom-part {
      padding-left: 4rem;
    }
    &__bottom-desc {
      max-width: 320px;
    }
  }
}
</style>
